import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ButtonGroup from 'antd/lib/button/button-group'
import { Button } from 'antd'

export interface TriviaNavigationProps {
  canProceed?: boolean
  hasPrevious?: boolean
  hasNext?: boolean
  onNextClick?: () => void
  onPrevClick?: () => void
}

export const TriviaNavigation = ({
  canProceed,
  hasPrevious = false,
  hasNext = true,
  onNextClick,
  onPrevClick,
}: TriviaNavigationProps) => (
  <ButtonGroup>
    {hasPrevious && (
      <Button onClick={onPrevClick} type="primary">
        <LeftOutlined />
        Previous Question
      </Button>
    )}
    {hasNext && (
      <Button type="primary" onClick={onNextClick} disabled={!canProceed}>
        Next Question
        <RightOutlined />
      </Button>
    )}
  </ButtonGroup>
)

export default TriviaNavigation
