import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Statistic,
  Typography,
} from 'antd'
import { useState } from 'react'

const { Title } = Typography

export interface GameOverModalProps {
  onClose: () => void
  visible: boolean
  points: number
}

export const GameOverModal = ({
  onClose,
  visible,
  points = 0,
}: GameOverModalProps) => {
  const [username, setUser] = useState('')
  const [password, setPassword] = useState('')

  console.log(username, password)

  return (
    <Modal
      centered
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      title="Game Over"
      footer={false}
    >
      <Row justify="center">
        <Col style={{ textAlign: 'center' }}>
          <Statistic title="Points" value={points} />
          <p>
            Update your high score with a username and password. If your
            username doesn't exist, it will be created.
          </p>
          <Form name="updateScore" layout="vertical" wrapperCol={{ span: 24 }}>
            <Form.Item>
              <Input
                type="text"
                placeholder="Username"
                onChange={({ target: { value } }) => setUser(value)}
                value={username}
                className="mb-0 md:mb-4"
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                placeholder="Password"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={async () => {
                const response = await fetch(
                  '/.netlify/functions/update-score',
                  {
                    method: 'POST',
                    headers: {
                      contentType: 'application/json',
                    },
                    body: JSON.stringify({ username, password, points }),
                  }
                )
                const { error, success } = await response.json()
                
                error && message.error(error)
                success && message.success(success)
              }}
            >
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}
export default GameOverModal
