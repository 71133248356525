import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Card, Col, Row, Space, Typography } from 'antd'
import { green, red, grey } from '@ant-design/colors'
import { PropsWithChildren } from 'react'

const { Title, Text } = Typography

interface QuestionCardProps {
  category: string
  difficulty: keyof typeof difficultyColors
  question: string
  choices?: string[]
  onAnswer: (answer: string) => void
  selectedChoice?: string
  answer?: string
}

const difficultyColors = {
  easy: 'green',
  medium: 'orange',
  hard: 'red',
}

const Label = ({ children }: PropsWithChildren<{}>) => (
  <Text strong>{children}:</Text>
)

const QuestionIcon = ({
  isCorrect,
  fontSize = 24,
}: {
  isCorrect: boolean | 'unanswered'
  fontSize?: number
}) => {
  switch (isCorrect) {
    case true:
      return <CheckCircleOutlined style={{ color: green.primary, fontSize }} />
    case false:
      return <CloseCircleOutlined style={{ color: red.primary, fontSize }} />
    default:
      return (
        <QuestionCircleOutlined style={{ color: grey.primary, fontSize }} />
      )
  }
}

export const QuestionCard = ({
  category,
  difficulty,
  question,
  choices = [],
  onAnswer,
  selectedChoice,
  answer,
}: QuestionCardProps) => (
  <article>
    <Card className="mt-4 mx-4 mb-8 md:mx-8">
      <header style={{ marginBottom: 16 }}>
        <Space size={16} className="mb-3">
          <QuestionIcon
            isCorrect={
              selectedChoice ? selectedChoice === answer : 'unanswered'
            }
          />
          <Space className="block sm:inline-flex">
            <Label>Category</Label>
            <Text>{category}</Text>
          </Space>
          <Space className="block sm:inline-flex">
            <Label>Difficulty</Label>
            <Text
              style={{ color: difficultyColors[difficulty] }}
            >{`${difficulty[0].toUpperCase()}${difficulty.slice(1)}`}</Text>
          </Space>
        </Space>
        <Title level={4}>
          <span dangerouslySetInnerHTML={{ __html: question }} />
        </Title>
      </header>

      <Row gutter={[16, 16]}>
        {choices.map((choice: string) => (
          <Col xs={{ span: 24 }} md={{ span: 12 }} key={choice}>
            <Button
              block
              className="text-xs sm:text-base"
              disabled={!!selectedChoice}
              style={{
                ...(selectedChoice === choice && {
                  borderColor:
                    selectedChoice === answer ? green.primary : red.primary,
                }),
              }}
              onClick={() => !selectedChoice && onAnswer(choice)}
            >
              <span dangerouslySetInnerHTML={{ __html: choice }} />
            </Button>
          </Col>
        ))}
      </Row>
    </Card>
  </article>
)

export default QuestionCard
