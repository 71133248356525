import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import {
  newGame,
  nextQuestion,
  prevQuestion,
  selectChoice,
  selectCurrentQuestion,
  selectTriviaNavigation,
} from './triviaSlice'
import QuestionCard from './components/QuestionCard'
import TriviaNavigation from './components/TriviaNavigation'
import { Col, Row, Statistic } from 'antd'
import GameOverModal from './components/GameOverModal'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function Trivia() {
  const triviaNavigation = useAppSelector(selectTriviaNavigation)
  const currentQuestion = useAppSelector(selectCurrentQuestion)
  const { points, questions, selectedChoices, status } = useAppSelector(
    (state) => state.trivia
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const dispatch = useAppDispatch()

  const loading = status === 'pending'

  useEffect(() => {
    questions.length > 0 &&
      selectedChoices.length === questions.length &&
      setIsModalVisible(true)
  }, [selectedChoices.length])

  return (
    <>
      <GameOverModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        points={points}
      />
      {questions.length ? (
        <Row justify="center">
          <Col span={18} lg={{ span: 18 }} className="text-center">
            <Row justify="space-between" align="middle">
              <Col span={6}>
                <Statistic title="Points" value={points} />
              </Col>
              <Col span={6}>
                <Statistic
                  title="Answered"
                  value={selectedChoices.length}
                  suffix={`/${questions.length}`}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={{ span: 24 }} lg={{ span: 20 }} xl={{ span: 18 }}>
            <QuestionCard
              {...currentQuestion}
              onAnswer={(answer) => dispatch(selectChoice(answer))}
            />
          </Col>

          <Col span={24} sm={{ span: 18 }} className="text-center mb-8">
            <TriviaNavigation
              onNextClick={() => dispatch(nextQuestion())}
              onPrevClick={() => dispatch(prevQuestion())}
              {...triviaNavigation}
            />
          </Col>
        </Row>
      ) : (
        loading && <LoadingSpinner />
      )}
    </>
  )
}
