import { statement } from '@babel/template'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '../../app/store'
import { randomSort } from '../utility'
import { fetchQuestions as fetchApiQuestions } from './api'

export interface TriviaState {
  status: 'idle' | 'pending' | 'failed'
  questions: Question[]
  currentQuestionIndex: number
  points: number
  selectedChoices: string[]
}

const initialState: TriviaState = {
  status: 'idle',
  questions: [],
  points: 0,
  currentQuestionIndex: 0,
  selectedChoices: [],
}

export type Difficulty = 'easy' | 'medium' | 'hard'

export type QuestionType = 'multiple' | 'boolean'
export interface Question {
  category: string
  type: QuestionType
  difficulty: Difficulty
  question: string
  correct_answer: string
  incorrect_answers: string[]
}

export const newGame = createAsyncThunk('trivia/newGame', async () => {
  const questions: Question[] = await fetchApiQuestions()

  return questions.map((question) => ({
    ...question,
    choices: randomSort([
      ...question.incorrect_answers,
      question.correct_answer,
    ]),
  }))
})

export const triviaSlice = createSlice({
  name: 'trivia',
  initialState,
  reducers: {
    selectChoice: (state, { payload }: PayloadAction<string>) => {
      const difficultyToPoints = {
        easy: 1,
        medium: 2,
        hard: 3,
      }

      state.selectedChoices.push(payload)

      if (
        state.questions[state.currentQuestionIndex]?.correct_answer === payload
      )
        state.points +=
          difficultyToPoints[
            state.questions[state.currentQuestionIndex]?.difficulty
          ]
    },
    nextQuestion: (state) => {
      state.currentQuestionIndex++
    },
    prevQuestion: (state) => {
      state.currentQuestionIndex--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newGame.pending, (state) => {
        state.questions = []
        state.status = 'pending'
        state.points = 0
        state.currentQuestionIndex = 0
        state.selectedChoices = []
      })
      .addCase(newGame.fulfilled, (state, action) => {
        state.status = 'idle'
        state.questions = action.payload
      })
  },
})

export const { selectChoice, nextQuestion, prevQuestion } = triviaSlice.actions

export const selectCurrentQuestion = ({ trivia }: RootState) => {
  return {
    ...trivia.questions[trivia.currentQuestionIndex],
    selectedChoice: trivia.selectedChoices[trivia.currentQuestionIndex],
    ...(trivia.selectedChoices.length > trivia.currentQuestionIndex && {
      answer: selectCurrentQuestionAnswer({ trivia }),
    }),
  }
}

export const selectCurrentQuestionAnswer = ({ trivia }: RootState) =>
  trivia.questions[trivia.currentQuestionIndex].correct_answer

export const selectPlayerCanMoveToNextQuestion = ({ trivia }: RootState) =>
  trivia.selectedChoices.length > trivia.currentQuestionIndex

export const selectHasPreviousQuestions = ({ trivia }: RootState) =>
  trivia.currentQuestionIndex > 0

export const selectHasNextQuestion = ({ trivia }: RootState) =>
  trivia.questions.length - 1 > trivia.currentQuestionIndex

export const selectTriviaNavigation = ({ trivia }: RootState) => ({
  canProceed: selectPlayerCanMoveToNextQuestion({ trivia }),
  hasPrevious: selectHasPreviousQuestions({ trivia }),
  hasNext: selectHasNextQuestion({ trivia }),
})

export default triviaSlice.reducer
