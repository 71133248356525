import React, { useEffect } from 'react'
import { useAppDispatch } from './app/hooks'
import { Button, PageHeader } from 'antd'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import Trivia from './features/trivia/Trivia'
import { newGame } from './features/trivia/triviaSlice'
import Leaderboard from './features/leaderboard/Leaderboard'

export default function App() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(newGame())
  }, [])

  return (
    <div className="App">
      <Router>
        <PageHeader
          title="Trivia"
          subTitle="Made fun with Redux and Ant Design."
          className="bg-gray-100 mb-4"
          extra={[
            <Link key="leaderboard" to="/leaderboard">
              Leaderboard
            </Link>,
            <Button
              key="new"
              type="primary"
              onClick={() => dispatch(newGame())}
            >
              <Link to="/">New Game</Link>
            </Button>,
          ]}
        />
        <Switch>
          <Route key="home" path="/leaderboard">
            <Leaderboard />
          </Route>
          <Route path="/">
            <Trivia />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}
