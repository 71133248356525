import { Col, Typography } from 'antd'
import { List } from 'antd'
import { FC, useEffect, useState } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner'

export interface LeaderboardProps {
  users?: any[]
}

export const Leaderboard: FC<LeaderboardProps> = ({ users: initialUsers }) => {
  const [users, setUsers] = useState<{ username: string; points: string }[]>(
    initialUsers || []
  )
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await fetch('/.netlify/functions/leaderboard')
      const data = await response.json()
      setUsers(data)
      setLoading(false)
    })()
  }, [])

  return loading ? (
    <LoadingSpinner />
  ) : (
    <List
      className="p-4 m-4"
      size="large"
      dataSource={users}
      renderItem={({ username, points }) => (
        <List.Item>
          <Col span={12}>
            <Typography.Text>{username}</Typography.Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Typography.Text>{points}</Typography.Text>
          </Col>
        </List.Item>
      )}
    />
  )
}

export default Leaderboard
